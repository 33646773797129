/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import "../css/form.css";
import "../css/twosectiongrid.css";
import FormData from "form-data";
import mobile_image from "../assets/images/formimg.png";
import { countries } from "../helpers/countryList";

import SuccessMessage from "./SuccessMessage";

//==== image import =================================
import img_youtube from "../assets/images/youtube.png";
import img_facebook from "../assets/images/facebook.jpg";
import img_twitter from "../assets/images/twitter.png";
import img_instagram from "../assets/images/instagram.jpg";
//==== image import =================================

export default function Form({
  userId,
  userImage,
  title,
  partyImg,
  qrWebsitePartyName,
  qrWebsitePartyImage,
  qrWebsitePartyImage2,
  qrWebsitePartyImage3,
  youtube,
  facebook,
  instagram,
  twitter,
  thanksImage
}) {
  const [name, setname] = useState("");
  const [gender, setgender] = useState("");
  const [email, setemail] = useState("");
  const [contact_number, setcontact_number] = useState("");
  const [WhatsApp_No, setWhatsApp_No] = useState("");
  const [Country, setCountry] = useState("");
  const [State, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Tahsil, setTahsil] = useState("");
  const [City_Block, setCity_Block] = useState("");
  const [Village_Colony, setVillage_Colony] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [bloodGroup, setbloodGroup] = useState("");
  const [occupassion, setoccupassion] = useState("");

  const [vidhanSabha, setvidhanSabha] = useState("");
  const [boothNumber, setboothNumber] = useState("");
  const [position, setposition] = useState("");
  // const [addresses, setaddresses] = useState("");

  const [successMsg, setsuccessMsg] = useState("");
  const [autofill, setautofill] = useState("yes");

  function handleSubmit(e) {
    e.preventDefault();

    // validations
    // start ----------------------------------------------------------------
    if (contact_number.length !== 10) {
      return alert("Please enter correct contact number");
    }
    if (WhatsApp_No.length !== 10 && WhatsApp_No.length !== 0) {
      return alert("Please enter correct WhatsApp number");
    }
    // if (PinCode.length !== 6) {
    //   return alert("Please enter correct pin code");
    // }

    //     name
    // middle_name
    // last_name

    var hasNumber = /\d/;
    if (hasNumber.test(name)) {
      return alert("Please Don't include numbers First name");
    }
    //true

    //end ----------------------------------------------------------------

    const store = new FormData();
    store.set("_name", name);
    store.set("_gender", gender);
    store.set("_email", email);
    store.set("_contact_number", contact_number);
    store.set("_WhatsApp_No", WhatsApp_No);
    store.set("_Country", Country);
    store.set("_State", State);
    store.set("_District", District);
    store.set("_Tahsil", Tahsil);
    store.set("_City_Block", City_Block);
    store.set("_Village_Colony", Village_Colony);
    store.set("_PinCode", PinCode);
    store.set("_bloodGroup", bloodGroup);
    store.set("_occupassion", occupassion);

    store.set("_vidhanSabha", vidhanSabha);
    store.set("_boothNumber", boothNumber);
    store.set("_position", position);
    // store.set("_addresses", addresses);

    store.set("_client_id", userId);

    console.log(`${process.env.REACT_APP_SERVERPORT2}/store_user_web_form/`);

    fetch(`${process.env.REACT_APP_SERVERPORT2}/store_user_web_form`, {
      method: "POST",
      body: store,
    })
      .then((value) => {
        // console.log(value);
        return value.json();
      })
      .then((v) => {
        if (v.message === "success" || v.message === "user_exists") {
          setsuccessMsg("Your request posted successfully.");
          setname("");
          setgender("Male");
          setemail("");
          setcontact_number("");
          setWhatsApp_No("");
          setCountry("");
          setState("");
          setDistrict("");
          setTahsil("");
          setCity_Block("");
          setVillage_Colony("");
          setPinCode("");
          setvidhanSabha("");
          setboothNumber("");
          // setaddresses("");
          // window.scroll(0,0)
        }

        if (v.message === "client_id_err") {
          alert("Something went wrong, Please Refresh the Page and try again.");
        }
        if (v.message === "mobile_exists") {
          alert("Mobile Number already exists, Please use another one.");
        }
        if (v.message === "err") {
          alert("Something went wront, Please try again.");
        }
      })
      .catch((err) => {
        alert("Something went wront, Please try again.");
      });
  }

  function handlePincondeChange(e) {
    if (e.target.value.length === 6) {
      // alert("please wait we will find the city of you");

      fetch(`http://localhost:8002/api/getdatafrompincode/${e.target.value}`)
        .then((v) => {
          return v.json();
        })
        .then((v) => {
          if (v.data) {
            if (v.data.PostOffice) {
              setCountry(v.data.PostOffice[0].Country);
              setState(v.data.PostOffice[0].State);
              setDistrict(v.data.PostOffice[0].District);
            } else {
              setautofill("yes");
            }
          } else {
            // alert("nog good");
            setautofill("yes");
          }
        })
        .catch((err) => {
          setautofill("yes");
        });
    }
  }
  return (
    <div className="container_div">
      <div>
        <div className="header_web_form">
          <div>
            <img className="partyLogo" src={partyImg} alt="" />
            {window.visualViewport.width > 768 && (
              <>
                <img
                  className="partyLogo"
                  style={{ visibility: "hidden" }}
                  src={partyImg}
                  alt=""
                />
              </>
            )}
          </div>
          <div>
            <h1>{qrWebsitePartyName}</h1>
          </div>
          <div>
            <img className="partyLogo" src={qrWebsitePartyImage} alt="" />
            {window.visualViewport.width > 768 && (
              <>
                <img className="partyLogo" src={qrWebsitePartyImage2} alt="" />
                <img className="partyLogo" src={qrWebsitePartyImage3} alt="" />
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit} className="onlineDarbarMainForm">
          <div className="leader_main_image">
            <img
              src={userImage}
              style={
                window.visualViewport.width > window.visualViewport.height
                  ? { width: "96vh" }
                  : { width: "90vw" }
              }
              alt=""
            />
          </div>

          <div className="main_form_center">
            <div>
              <div className="home_page_heading">
                <div>
                  <h1>V-analytics </h1>
                  <h3 style={{ color: "#3c486b", fontWeight: "bold" }}>
                    Empowering Politics and Tranforming Future
                  </h3>
                </div>

                <div className="logo_heading_custom">
                  <h1 style={{ color: "#ff6969" }}>{title}</h1>
                </div>
              </div>
              <div className="half-input">
                <label htmlFor="">पूरा नाम</label>
                <input
                  type="text"
                  placeholder="Full Name *"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                  required
                  className="online-darbar-form "
                />
              </div>

              <div className="half-input">
                <label htmlFor="">ईमेल</label>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  // required
                  className="online-darbar-form "
                />
              </div>

              <div className="half-input">
                <label htmlFor="">लिंग </label>
                <select
                  type="text"
                  placeholder="Gender"
                  value={gender}
                  onChange={(e) => {
                    setgender(e.target.value);
                  }}
                  // required
                  className="online-darbar-form "
                >
                  {["Select Gender", "Male", "Female", "Other"].map(
                    (value, index) => {
                      return (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>

              <div className="half-input">
                <label htmlFor="">ब्लड ग्रुप</label>
                <select
                  type="text"
                  placeholder="Blood Group"
                  value={bloodGroup}
                  onChange={(e) => {
                    setbloodGroup(e.target.value);
                  }}
                  // required
                  className="online-darbar-form "
                >
                  {[
                    "Select Blood Group",
                    "A+",
                    "A-",
                    "B+",
                    "B-",
                    "AB+",
                    "AB-",
                    "O+",
                    "O-",
                  ].map((value, index) => {
                    return (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="half-input">
                <label htmlFor="">संपर्क नंबर</label>
                <input
                  type="number"
                  placeholder="Contact Number *"
                  value={contact_number}
                  onChange={(e) => {
                    setcontact_number(e.target.value);
                  }}
                  // required
                  className="online-darbar-form "
                />
              </div>
              <div className="half-input">
                <label htmlFor="">व्हाट्सएप नंबर</label>

                <input
                  type="number"
                  placeholder="Watsapp Number"
                  value={WhatsApp_No}
                  onChange={(e) => {
                    setWhatsApp_No(e.target.value);
                  }}
                  className="online-darbar-form "
                />
              </div>
              <div className="half-input">
                <label htmlFor="">पिन कोड</label>
                <input
                  type="number"
                  placeholder="Enter Pincode"
                  value={PinCode}
                  onChange={(e) => {
                    // handlePincondeChange(e);
                    setPinCode(e.target.value);
                  }}
                  className="online-darbar-form "
                />
              </div>
              {/* ================================================================================ */}

              {autofill === "yes" ? (
                <>
                  <div className="half-input">
                    <label htmlFor="">देश</label>
                    <select
                      type="text"
                      placeholder="Select Country "
                      value={Country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                        // setState(countries[Country][0]);
                        countries[e.target.value] &&
                        countries[e.target.value] !== ""
                          ? // ? setState(Object.keys(countries[e.target.value])[0])
                            setState("Rajasthan")
                          : setState("");
                        countries[e.target.value] &&
                        countries[e.target.value][
                          Object.keys(countries[e.target.value])[0]
                        ] &&
                        countries[e.target.value][
                          Object.keys(countries[e.target.value])[0]
                        ] !== ""
                          ? setDistrict(
                              countries[e.target.value][
                                Object.keys(countries[e.target.value])[0]
                              ][0]
                            )
                          : setDistrict("");
                      }}
                      className="online-darbar-form "
                    >
                      <option>Select Country</option>
                      {Object.keys(countries).map((value, index) => {
                        return (
                          <option value={value} key={index}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="half-input">
                    <label htmlFor="">राज्य</label>
                    <select
                      type="text"
                      placeholder="Select State"
                      value={State}
                      onChange={(e) => {
                        setState(e.target.value);
                        countries[Country] &&
                        countries[Country][e.target.value] &&
                        countries[Country][e.target.value] !== ""
                          ? setDistrict(countries[Country][e.target.value][0])
                          : setDistrict("");
                      }}
                      className="online-darbar-form "
                    >
                      {countries[Country] &&
                        countries[Country] !== "" &&
                        Object.keys(countries[Country]).map((value, index) => {
                          return (
                            <option value={value} key={index}>
                              {value}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="half-input">
                    <label htmlFor="">ज़िला</label>
                    <select
                      type="text"
                      placeholder="Select District"
                      value={District}
                      onChange={(e) => {
                        setDistrict(e.target.value);
                      }}
                      className="online-darbar-form "
                    >
                      {countries[Country] &&
                        countries[Country][State] &&
                        countries[Country][State].map((value, index) => {
                          return (
                            <option value={value} key={index}>
                              {value}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </>
              ) : (
                <>
                  <div className="half-input">
                    <label htmlFor="">देश</label>
                    <input
                      type="text"
                      placeholder="Select Country"
                      value={Country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      className="online-darbar-form "
                    />
                  </div>
                  <div className="half-input">
                    <label htmlFor="">राज्य</label>
                    <input
                      type="text"
                      placeholder="Select State"
                      value={State}
                      onChange={(e) => {
                        setState(e.target.setState);
                      }}
                      className="online-darbar-form "
                    />
                  </div>
                  <div className="half-input">
                    <label htmlFor="">ज़िला</label>
                    <input
                      type="text"
                      placeholder="Select District"
                      value={District}
                      onChange={(e) => {
                        alert("District cannot be changed manually");
                      }}
                      className="online-darbar-form "
                    />
                  </div>
                </>
              )}

              {/* -------------- */}

              {/* ================================================================================ */}
              <div className="half-input">
                <label htmlFor="">तहसील</label>
                <input
                  type="text"
                  placeholder="Select Tahsil"
                  value={Tahsil}
                  onChange={(e) => {
                    setTahsil(e.target.value);
                  }}
                  className="online-darbar-form "
                />
              </div>
              <div className="half-input">
                <label htmlFor="">शहर / खंड</label>
                <input
                  type="text"
                  placeholder="Select city / Block"
                  value={City_Block}
                  onChange={(e) => {
                    setCity_Block(e.target.value);
                  }}
                  // required
                  className="online-darbar-form "
                />
              </div>
              <div className="half-input">
                <label htmlFor="">गाँव / कालोनी</label>
                <input
                  type="text"
                  placeholder="Village / Colony"
                  value={Village_Colony}
                  onChange={(e) => {
                    setVillage_Colony(e.target.value);
                  }}
                  // required
                  className="online-darbar-form "
                />
              </div>

              <div className="half-input">
                <label htmlFor="">विधान सभा सीट</label>
                <input
                  type="text"
                  placeholder="Enter Vidhan Sabha Seat"
                  value={vidhanSabha}
                  onChange={(e) => {
                    setvidhanSabha(e.target.value);
                  }}
                  className="online-darbar-form "
                />
              </div>
              <div className="half-input">
                <label htmlFor="">बूथ संख्या / बूथ का नाम</label>
                <input
                  type="text"
                  placeholder="Enter Booth Number / Booth Name"
                  value={boothNumber}
                  onChange={(e) => {
                    setboothNumber(e.target.value);
                  }}
                  // required
                  className="online-darbar-form "
                />
              </div>
              <div className="half-input">
                <label htmlFor="">पद</label>
                <input
                  type="text"
                  placeholder="Position"
                  value={position}
                  onChange={(e) => {
                    setposition(e.target.value);
                  }}
                  // required
                  className="online-darbar-form "
                />
              </div>
              <div className="half-input">
                <label htmlFor="">पेशा / व्यवसाय</label>
                <input
                  type="text"
                  placeholder="Occupation"
                  value={occupassion}
                  onChange={(e) => {
                    setoccupassion(e.target.value);
                  }}
                  // required
                  className="online-darbar-form"
                />
              </div>
              {/* <textarea
                  type="text"
                  placeholder="Enter Adderss"
                  value={addresses}
                  onChange={(e) => {
                    setaddresses(e.target.value);
                  }}
                  required
                  className="online-darbar-form"
                /> */}
            </div>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
      <div>
        <div className="social-media-div">
          {youtube !== "" && (
            <div className="social-media">
              <a href={youtube}>
                <img alt="social media image" src={img_youtube} />
              </a>
            </div>
          )}
          {facebook !== "" && (
            <div className="social-media">
              <a href={facebook}>
                <img alt="social media image" src={img_facebook} />
              </a>
            </div>
          )}
          {instagram !== "" && (
            <div className="social-media">
              <a href={instagram}>
                <img alt="social media image" src={img_twitter} />
              </a>
            </div>
          )}
          {twitter !== "" && (
            <div className="social-media">
              <a href={twitter}>
                <img alt="social media image" src={img_instagram} />
              </a>
            </div>
          )}
        </div>
      </div>

      <SuccessMessage
        image={thanksImage}
        successMsg={successMsg}
        setsuccessMsg={setsuccessMsg}
      />
    </div>
  );
}
