import React from "react";
import "../css/success_msg_box.css";
import youtube from "../assets/images/youtube.png"

export default function SuccessMessage({image, successMsg, setsuccessMsg }) {
  return (
    <div>
      {successMsg !== "" && (
        <div className="success-msg-box">
          <div className="msg-box">
            <div className="img-thanks">
              <img src={image} alt="" />
            </div>
            <h1>Success</h1>
            <h3>Thanks for becoming the Member</h3>

            <button  onClick={() => setsuccessMsg("")}>ok</button>
          </div>
        </div>
      )}
    </div>
  );
}
