import { useEffect, useState } from "react";
import "./App.css";
import Form from "./components/Form";

function App() {
  const [webStatus, setwebStatus] = useState("checking");
  const [userId, setUserId] = useState("");
  const [userImage, setuserImage] = useState("");
  const [title, settitle] = useState("");
  const [partyImg, setpartyImg] = useState("");
  const [qrWebsitePartyName, setqrWebsitePartyName] = useState("");
  const [qrWebsitePartyImage, setqrWebsitePartyImage] = useState("");
  const [qrWebsitePartyImage2, setqrWebsitePartyImage2] = useState("");
  const [qrWebsitePartyImage3, setqrWebsitePartyImage3] = useState("");
  const [thanksImage, setthanksImage] = useState("");
  const [youtube, setyoutube] = useState("");
  const [facebook, setfacebook] = useState("");
  const [instagram, setinstagram] = useState("");
  const [twitter, settwitter] = useState("");
  

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVERPORT3}/qrwebsitechecking/${window.location.host}`
    )
      .then((v) => {
        return v.json();
      })
      .then((v) => {
        if (v.message === "success") {
          setwebStatus("ok");
          setUserId(v.dbData.client_id);
          setuserImage(v.dbData.qr_website_image);
          settitle(v.dbData.qr_website_title);
          setpartyImg(v.dbData.qr_party_logo);
          setqrWebsitePartyName(v.dbData.qrWebsitePartyName);
          setqrWebsitePartyImage(v.dbData.qrWebsitePartyImage);
          setqrWebsitePartyImage2(v.dbData.qrWebsitePartyImage2);
          setqrWebsitePartyImage3(v.dbData.qrWebsitePartyImage3);
          setthanksImage(v.dbData.thanksImage);
          setyoutube(v.dbData.youtube);
          setfacebook(v.dbData.facebook);
          setinstagram(v.dbData.instagram);
          settwitter(v.dbData.twitter);
          console.log(v);
        } else if (v.message === "not_ready") {
          setwebStatus("notReady");
        } else if (v.message === "failed") {
          // alert(v.message)
          setwebStatus("notOk");
        } else {
          // alert("something went wrong, while authenticating.");
          setwebStatus("notOk");
        }
      })
      .catch((err) => {
        // alert("something went wrong, while authenticating.");
        setwebStatus("ise");
      });
  });

  if (webStatus === "checking") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading...
      </div>
    );
  }

  if (webStatus === "ok") {
    return (
      <div className="App">
        <Form
          userId={userId}
          userImage={userImage}
          title={title}
          partyImg={partyImg}
          qrWebsitePartyName={qrWebsitePartyName}
          qrWebsitePartyImage={qrWebsitePartyImage}
          qrWebsitePartyImage2={qrWebsitePartyImage2}
          qrWebsitePartyImage3={qrWebsitePartyImage3}
		  thanksImage={thanksImage}
          youtube={youtube}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </div>
    );
  }

  if (webStatus === "notOk") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Something Went Wrong, Please connet with owner
      </div>
    );
  }
  if (webStatus === "notReady") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Website is not ready to show, Website will be available when it is
        updated.
      </div>
    );
  }
  if (webStatus === "ise") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Internal Server Error, Please connet with owner
      </div>
    );
  }
}

export default App;
